import React, { useState, useEffect } from "react"
import {
    Switch,
    Route,
    Redirect,
    useLocation
} from "react-router-dom"
import { reactFormatter } from 'react-tabulator'
import MainTemplate from '../../custom_components/templates/MainTemplate.js'
import { TabulatorTemplate } from 'custom_components/templates/TabulatorTemplate.js'
import ApplicationCreate from 'pages/training/ApplicationCreate.js'
import ApplicationInfo from 'pages/training/ApplicationInfo.js'
import { useFetchPost, useUserPermission,sessionSetLoading } from '../../custom_hooks/Actions.js';
import { flipTrueFalse } from "helper_functions/Helper.js"
import Swal from 'sweetalert2'

const path = process.env.REACT_APP_PATH
const domain = process.env.REACT_APP_DOMAIN

function parseTraining(data) {
    let dataForActive = []
    let dataForInactive = []
    try {
        for (let i = 0; i < data.length-1; i++) {
            data[i].forEach((number, index, array) => {
                const obj = {
                    no: index+1,
                    id: array[index]['trainingID'],
                    code: array[index]['employeeCode'],
                    name: array[index]['employeeName'],
                    course: array[index]['courseName'],
                    startDate: array[index]['startDate'],
                    endDate: array[index]['endDate'],
                    duration: array[index]['duration'],
                    submitby: array[index]['submitBy'],
                    status: array[index]['status'],
                    // action: ""
                }
                if (i === 0) {
                    if (data[i][0] !== 0) {
                        dataForActive.push(obj);
                    }
                } else {
                    if (data[i][0] !== 0) {
                        dataForInactive.push(obj);
                    }
                }
            })
        }
    } catch {
        return ["", ""]
    }
    const parsedData = [dataForActive, dataForInactive]
    return parsedData
}

function getRole(data){
    const roleData = data;
    return roleData;
}

function Application({ permission }) {
   
    let location = useLocation();
    const [refetch, setRefetch] = useState(0)
    // window.location.toString().split(path)[0] + path +
    const trainingData = useFetchPost(  domain + "/api/TrainingModule/trainingApplication.php?action", {}, refetch)
    const parsedData = parseTraining(trainingData.data)
    // const roleData  = getRole(trainingData.data[2])

    const roleData = trainingData.data[2];
    const tabs = ["Active", "History"]
    const defaultTab = tabs[0]
    const [activeTab, setActiveTab] = useState(defaultTab)
    const [redirect, setRedirect] = useState({ allow: false })
    const [TAID, setTAID] = useState('')
    const [role, setRole] = useState({})

    function handleRefetch() {
        setRefetch(Math.random())
    }

    function WithdrawnButton(props) {
        const cellData = props.cell._cell.row.data;
        // if(cellData.status != 'Approved'  && props.tabs != 'Active'){
        //    return null;
        // }else{
            return (  
                <button type='button' className='btn btn-primary btn-sm' >Withdrawn</button>
            )
        // }
    }

    function handleUserPasswordRedirect(e) {
        e.preventDefault()
        // setTAID(id)
    }

    // useEffect(()=>{
      
    //     fetch(domain + "/api/EmployeeModule/user_role.php", {
    //         method: "GET",
    //         // mode: "same-origin",
    //         credentials: "same-origin"
    //     })
    //         .then(res => res.json())
    //         .then(json => {
    //            setRole(json);
              
    //         })
    //         .catch(err => { console.log(err) })
    // }, [role])

    useEffect(() => {
          
        // fetch(domain + "/api/EmployeeModule/user_role.php", {
        //     method: "GET",
        //     mode: "same-origin",
        //     credentials: "same-origin"
        // })
        // .then(res => res.json())
        // .then(json => {
        //     setRole(json);
        // })
        // .catch(err => { console.log(err) })

        if (TAID != '') {
            sessionSetLoading(true)
            fetch(domain + "/api/TrainingModule/trainingApplication.php?action=withdrawn", {
                method: "POST",
                mode: "same-origin",
                credentials: "same-origin",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    trainingID: TAID,
                })
            })
                .then(res => res.json())
                .then(json => {
                    if (json[0] === 1) {
                        handleRefetch()
                    }
                    setTAID('');
                    Swal.fire(
                        json[0] === 1 ? 'Success!' : 'Error!',
                        json[1],
                        json[0] === 1 ? 'success' : 'error'
                    )
                    sessionSetLoading()
                })
            
            
        }
    }, [TAID])
console.log('roledata', roleData);
    const columns = [
        {title: "Sr/No.", field: "no", headerFilter: "input"},
        {
            title: "Training Application ID", field: "id", headerFilter: "input", formatter: "link", formatterParams: {
                labelField: "id",
                urlPrefix: "application/"
            },
            //hacky way to make tabulator play well with react router
            cellClick: function (e, cell) { 
                e.preventDefault()
                const rowInfo = cell._cell.row.data
                setRedirect({ allow: true, id: rowInfo.id }) //here we update state
            }
        },
        { title: "Employee Code", field: "code", headerFilter: "input" },
        { title: "Employee Name", field: "name", headerFilter: "input" },
        { title: "Course Name", field: "course", headerFilter: "input" },
        {
            title: "Start Date", field: "startDate", headerFilter: "input", formatter: "datetime", formatterParams: {
                inputFormat: "DD/MM/YYYY",
                outputFormat: "DD/MM/YYYY",
                invalidPlaceholder: "N/A",
            }
        },
        {
            title: "End Date", field: "endDate", headerFilter: "input", formatter: "datetime", formatterParams: {
                inputFormat: "DD/MM/YYYY",
                outputFormat: "DD/MM/YYYY",
                invalidPlaceholder: "N/A",
            }
        },
        { title: "Duration", field: "duration", headerFilter: "input" },
        { title: "Submit by", field: "submitby", headerFilter: "input" },
        { title: "Status", field: "status", headerFilter: "input" },
        roleData == 'HR Manager' || roleData == 'HR' || roleData == 'HSE Manager'?
         {
            title: "Action", field:"action", headerFilter: "input", 
            formatter: reactFormatter(
                <WithdrawnButton 
                    pathname={location.pathname} 
                    tabs={tabs} 
                    activeTab={activeTab} 
                    permission={permission} 
                    handleUserPasswordRedirect={handleUserPasswordRedirect} 
                    setTAID={setTAID}
                    handleRefetch={handleRefetch}
                />
            ),

            cellClick: function (e, cell) { 
                setTAID(cell._cell.row.data.id);
            }
            
        }
        :  { title: "Action",field:"action", visible :false },
    ]
    return (
        redirect.allow ?
            <Redirect push to={"/application/"+redirect?.id} />
        :
            trainingData.status === "fetched" ?
                <TabulatorTemplate 
                    moduleName={"Training"}
                    columns={columns}
                    moduleData={parsedData}
                    tabs={tabs}
                    disableWidget={permission.add ? flipTrueFalse(permission.add) : true }
                    handleRefetch={handleRefetch}
                />
            : trainingData.status === "fetching" ?
                <div>Loading...</div>
            :
                <div>Training is not available</div>
    )
}

function ApplicationContainer(props) {
    const permission = useUserPermission(3)

    return (
        <MainTemplate
            content={
                <Switch>
                    {
                        permission.add ? <Route exact path="/:page/create" children={<ApplicationCreate />} /> : null
                    }
                    {
                        permission.view ? <Route path="/:page/:id" children={<ApplicationInfo />} /> : null
                    }
                    {
                        permission.view ? <Route path="" children= {<Application permission={permission} />} /> : null
                    }
                </Switch>
            }
        />
    )
}

export default ApplicationContainer;