import React, { useState, useEffect } from 'react'

const path = process.env.REACT_APP_PATH

function useUserPermission(permissionIndex) {
    //permission indexes for reference
    //in most cases edit and remove are paired
    //in other cases add and edit are paired in the case of TrainingPayment
    //0 Employee View/Add/Edit/Remove completed
    //1 Certificate View/Add/Edit/Remove completed
    //2 UserAccount View/Add/Edit/Remove completed
    //3 Application View/Add/Edit completed to be checked
    //4 Feedback View/Add completed to be checked
    //5 Evaluation View/Add completed to be checked
    //6 NOT USED
    //7 ApplyTrainingCourse completed to be checked
    //8 TrainingRecommend View/Edit completed to be checked
    //9 TrainingApproval View/Edit completed to be checked
    //10 TrainingPayment View/Add/Edit completed to be checked
    //11 UserRightAccess completed to be checked
    //12 Support View/Submit/Reply *Reply is meant for others(Sadmin) to reply, submit is for users completed to be checked
    //13 Report View/Download completed to be checked, download not implemented

    const data = sessionStorage.getItem('userPermission') ? JSON.parse(sessionStorage.getItem('userPermission')) : null
    let permission = {}
    console.log("data[permissionIndex]",data[permissionIndex]);
    data[permissionIndex].forEach((element, index) => { 
        console.log('Index',index);
        switch(permissionIndex) {
            case 0:
            case 1:
            case 2:
                if (index === 0) {
                    permission['view'] = element
                } else if (index === 1) {
                    permission['add'] = element
                } else if (index === 2) {
                    permission['edit'] = element
                } else if (index === 3) {
                    permission['del'] = element
                }
                break
            case 3:
            case 10:
                if (index === 0) {
                    permission['view'] = element
                } else if (index === 1) {
                    permission['add'] = element
                } else if (index === 2) {
                    permission['edit'] = element
                }
                break
            case 4:
            case 5:
                if (index === 0) {
                    permission['view'] = element
                } else if (index === 1) {
                    permission['add'] = element
                }
                break
            case 8:
            case 9:
                if (index === 0) {
                    permission['view'] = element
                } else if (index === 1) {
                    permission['edit'] = element
                }
            case 7:
            case 11:
                if (index === 0) {
                    permission['use'] = element
                }
                break
            case 12:
                if (index === 0) {
                    permission['view'] = element
                } else if (index === 1) {
                    permission['submit'] = element
                } else if (index === 2) {
                    permission['reply'] = element
                }
                break;
            case 13:
                if (index === 0) {
                    permission['view'] = element
                } else if (index === 1) {
                    permission['download'] = element
                }
                break;
            case 15:
                if (index === 0) {
                   permission["viewLeaveApproval"] = element;
                } else if (index === 1) {
                   permission["editLeaveApproval"] = element;
                }
                break;
            case 16:
            case 19:
                if (index === 0) {
                permission["view"] = element;
                } else if (index === 1) {
                permission["add"] = element;
                } else if (index === 2) {
                permission["edit"] = element;
                }
                break;
            case 17:
            case 20:
                if (index === 0) {
                permission["viewApproval"] = element;
                } else if (index === 1) {
                permission["editApproval"] = element;
                }
                break;
            case 21:
                if (index === 0) {
                permission["viewRecommend"] = element;
                } else if (index === 1) {
                permission["editRecommend"] = element;
                }
                break;
            case 25:
                // Staff Appraisal
                if(index === 0){
                    permission["viewStaff"] = element;
                }else if(index === 1){
                    permission["editStaff"] = element
                }
                break;
            case 26:
                //Supervisor Appraisal
                if(index === 0){
                    permission["viewSuper"] = element;
                }else if(index === 1){
                    permission["editSuper"] = element;
                }
                break;
            case 27:
                //Manager Approval on Staff Appraisal
                if(index === 0){
                    permission["viewManagerApproval"] = element;
                }else if(index === 1){
                    permission["editManagerApproval"] = element;
                }
                break;
            case 28:
                //Director Approval on Staff Appraisal
                if(index === 0){
                    permission["viewDirectorApproval"] = element;
                }else if(index === 1){
                    permission["editDirectorApproval"] = element; 
                }
                break;
            case 29:
                //setup for Staff Appraisal
                if(index === 0){
                    permission["viewSetupPage"] = element;
                }
                break;
            case 35:
                //permission for Invoice Scanning
                if(index === 0){
                    permission["view"] = element;
                }else if(index === 1){
                    permission["add"] = element;
                }
                break;
            case 36:
                //permission for Do Scanning
                if(index === 0){
                    permission["view"] = element;
                }else if(index === 1){
                    permission["add"] = element;
                }
                break;
            case 37:
                //permission for manager approval on Invoice Module
                if(index === 0){
                    permission["view"] = element;
                }else if(index === 1){
                    permission["Edit"] = element;
                }
                break;
            case 38:
                //permission for director approval on Invoice Module
                if(index === 0){
                    permission["view"] = element;
                }else if(index === 1){
                    permission["Edit"] = element;
                }
                break;
            default:
                break;
        }
    })
    
    return permission
}

function useFetchGet(query, refetch = 0) {
    const [status, setStatus] = useState(null);
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            setStatus('fetching')
            fetch(query, {
                method: "GET",
                mode: "same-origin",
                credentials: "same-origin",
                headers: { 'Content-Type': 'application/json' },
            })
            .then(res => res.json())
            .then(json => {
                setData(json);
                setStatus('fetched')
            })
            .catch(err => {
                setStatus(err)
            })
        }
        fetchData()
    }, [refetch])
    return { status, data }
}

function useFetchPost(query, postData, refetch = 0) {
    const [status, setStatus] = useState(null);
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            setStatus('fetching')
            fetch(query, {
                method: "POST",
                mode: "same-origin",
                credentials: "same-origin",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(postData)
            })
            .then(res => res.json())
            .then(json => {
                setData(json);
                setStatus('fetched')
            })
            .catch(err => {
                setStatus(err)
            })
        }
        fetchData()
    }, [refetch])
    return { status, data }
}

function sessionSetLoading(loading = false) {
    sessionStorage.setItem('loading', loading ) // true or false
}

export  { useUserPermission, useFetchGet, useFetchPost, sessionSetLoading }