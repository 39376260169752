import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { reactFormatter } from "react-tabulator";
import MainTemplate from "../../custom_components/templates/MainTemplate.js";
import {
  TabulatorTemplate,
  FullTabulatorTemplate,
} from "custom_components/templates/TabulatorTemplate.js";
import InvoiceApplicationCreate from "pages/finance/InvoiceApplicationCreate.js";
import invoiceApplicationDetails from "pages/finance/invoiceApplicationDetails.js";
import {
  useFetchPost,
  useUserPermission,
  sessionSetLoading,
} from "../../custom_hooks/Actions.js";
import { flipTrueFalse } from "helper_functions/Helper.js";
import Swal from "sweetalert2";

const path = process.env.REACT_APP_PATH
const domain = process.env.REACT_APP_DOMAIN


function parseInvoiceApplication(data) {
  let dataForActive = [];
  let dataForInactive = [];
  try {
    for (let i = 0; i < data.length - 1; i++) {
      data[i].forEach((number, index, array) => {
        const obj = {
          no: index + 1,
          id: array[index]["InvoiceApplicationID"],
          urgentAction : array[index]["urgent"],
          remark: array[index]["Remark"],
          submitby : array[index]["SubmitBy"],
          status: array[index]["status"],
          action: [],
        };
        
      });
    }
  } catch {
    return ["", ""];
  }
  const parsedData = [dataForActive, dataForInactive];
  return parsedData;
}

function getRole(data) {
  const role = data;

  return role;
}

function InvoiceApplication({ permission }) {

  let location = useLocation();
  const [refetch, setRefetch] = useState(0);

  // window.location.toString().split(path)[0] + path +
  const invoiceApplicationData = useFetchPost(
    domain + "/api/FinanceModule/InvoiceApplication.php?action",
    {},
    refetch
  );

  const parsedData = parseInvoiceApplication(invoiceApplicationData.data);
  const roleData = getRole(invoiceApplicationData.data[2]);
  const tabs = ["Active", "History"];
  const defaultTab = tabs[0];
  const [activeTab, setActiveTab] = useState(defaultTab);
  const [redirect, setRedirect] = useState({ allow: false });
  const [HLID, setHLID] = useState("");
  const [status, setStatus] = useState("");
  const [role, setRole] = useState("");

  function handleRefetch() {
    setRefetch(Math.random())
  }

  function handleUserPasswordRedirect(e) {
    e.preventDefault();
    
  }

  const columns = [
    { title: "Sr/No.", field: "no", headerFilter: "input" },
    {
      title: "IAID",
      field: "id",
      headerFilter: "input",
      formatter: "link",
      formatterParams: {
        labelField: "id",
        urlPrefix: "finance/scan-documents/",
      },
      //hacky way to make tabulator play well with react router
      cellClick: function (e, cell) {
        e.preventDefault();
        const rowInfo = cell._cell.row.data;
        setRedirect({ allow: true, id: rowInfo.id }); //here we update state
      },
    },
    { title: "Urgent", field: "urgentAction", headerFilter: "checkbox" },
    { title: "Submit by", field: "submitby", headerFilter: "input" },
    { title: "Remark", field: "remark", headerFilter: "input" },
    { title: "Status", field: "status", headerFilter: "input" },
   
  ];

  return redirect.allow ? (
    <Redirect push to={location.pathname + "/" + redirect?.id} />
  ) : invoiceApplicationData.status === "fetched" ? (
    
    <TabulatorTemplate
      moduleName={"InvoiceApplication"}
      columns={columns}
      moduleData={parsedData}
      tabs={tabs}
      disableWidget={permission.add ? flipTrueFalse(permission.add) : true}
      handleRefetch={handleRefetch}
    />
  ) : invoiceApplicationData.status === "fetching" ? (
    <div>Loading...</div>
  ) : (
    <div>Invoice Application is not available</div>
  );
}

function ApplicationContainer(props) {
  const permission = useUserPermission(35);
  console.log('permission',permission);

  return (
    <MainTemplate
      content={
        <Switch>
          {permission.add ? (
            <Route
              exact
              path="/:page/create"
              children={<InvoiceApplicationCreate />}
            />
          ) : null}
          {permission.view ? (
            <Route path="/:page/:id" children={<invoiceApplicationDetails />} />
          ) : null}
          {permission.view ? (
            <Route path="" children={<InvoiceApplication permission={permission} />} />
          ) : null}
        </Switch>
      }
    />
  );
}

export default ApplicationContainer;
