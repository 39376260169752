import { CreateRecruitmentEditForm, CreateStaffAppraisalForm } from "custom_components/templates/FormTemplate";
import { useFetchPost } from "custom_hooks/Actions";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom"
import Swal from "sweetalert2";

const path = process.env.REACT_APP_PATH;
const domain = process.env.REACT_APP_DOMAIN

export default function StaffInfo({permission, from, empCode}){
    const history = useHistory();
    const routeParams = useParams();
    const [refetch, setRefetch] = useState(0);
    const [filled, setFilled] = useState()
    const evalOption = ["Unsatisfactory", "Less than satisfactory", "Satisfactory", "More than satisfactory", "Well ahead of standard"]
    
     //font style for rating bar
     const [isHover, setIsHover] = useState("0")
     const [hoverField, setHoverField] = useState()
 
     function handleMouseEnter(num, fieldName){
         setIsHover(num);
         setHoverField(fieldName);
     }
 
     function handleMouseLeave(){
         setIsHover("0");
     }

     function parseCustomData(dt){
         dt = dt.trim()
         dt = dt.replaceAll(/[^\B\s+|\s+\Ba-z0-9:",.{}-]/gi, '')
         dt = JSON.parse(dt)
        
        return dt
    }
 
     const buttonStyle = {
         width: '20%', 
         textAlign:'center', 
         border: '1px solid grey', 
         backgroundColor: 'white', 
         cursor:'pointer'
     }
 
     const buttonHoverStyle = {
         width: '20%', 
         textAlign:'center', 
         border: '1px solid grey', 
         backgroundColor: '#0dcaf0', 
         cursor:'pointer',
         color: 'white',
     }
 
     const viewButtonStyle = {
         width: '20%', 
         textAlign:'center', 
         border: '1px solid grey', 
         backgroundColor: 'white',
     }
 
     const viewButtonHoverStyle = {
         width: '20%', 
         textAlign:'center', 
         border: '1px solid grey', 
         backgroundColor: '#0dcaf0', 
         color: 'white',
     }
 
 
     const ratingContainer = {
         width: '100%', 
         display: 'flex', 
         justifyContent: 'center', 
         paddingTop: '5px'
     }
     //font style for rating bar
    
    const [field, setField] = useState({
        aId: {displayText: "ID", dataType:"text", val:"", disable:false},
        aOwner: {displayText: "Staff Code", dataType:"text", val:"", disable:false},
        Name: {displayText: "Staff Name", dataType:"text", val:"", disable:false},
        aReportManager: {displayText: "Immediate supervisor/ manager/ reviewer code", dataType:"text", val:"", required: true, disable:false},
        reportManagerName: {displayText: "Immediate supervisor/ manager/ reviewer", dataType:"text", val:"", required: true, disable:false},
        aPosition: {displayText: "Position", dataType:"text", val:"", required: true, disable:false},
        aYear: {displayText: "Appraisal Year", dataType:"selectOne", val:"", required: true, disable:false},
        dateJoin: {displayText: "Commencement Date", dataType:"selectGroup", val:"", required: true, disable:false},
        currentPositionYear: {displayText: "Current Service Year", dataType:"text", val:"", required: true, disable:false},
        aStatus: {displayText: "Appraisal Status", dataType:"selectOne", val:"", required: true, disable:false},
        aDueDate: {displayText: "Appraisal Due Date", dataType:"selectOne", val:"", required: true, disable:false},
        aFilled: {displayText: "Form filled", dataType:"text", val:"", disable:true},      
    })
    const [interSkill, setInterSkill] = useState({
        "withCoWorker": {displayText: "With co-workers", dataType:"selectOne", val:"", required: true, disable:false},
        "withSupervisor": {displayText: "With supervisors", dataType:"selectOne", val:"", required: true, disable:false},
        "withEndUsers": {displayText: "With end users/clients", dataType:"selectOne", val:"", required: true, disable:false},
        "teamParticipation": {displayText: "Team participation", dataType:"selectOne", val:"", required: true, disable:false},
        "teamcontributions": {displayText: "Team contributions", dataType:"selectOne", val:"", required: true, disable:false},
        "commitmentToTeamSuccess": {displayText: "Commitment to team success", dataType:"selectOne", required: true, val:"", disable:false},
        "achievement": {displayText: "Achievements/comments", dataType:"text", val:"", required: true, disable:false},
        "managerRating": {displayText: "Manager Rating", dataType:"selectOne", val:"", required: true, disable:false},
        "managerComment": {displayText: "Manager Comment", dataType:"text", val:"", required: true, disable:false},
        // "directorRating": {displayText: "Director Rating", dataType:"selectOne", val:"", required: true, disable:false},
        // "directorComment": {displayText: "Director Comment", dataType:"text", val:"", required: true, disable:false},
    })
    const [techSkill, setTechSkill] = useState({
        "jobKnowledge": {displayText: "Job knowledge", dataType:"selectOne", val:"", required: true, disable:false},
        "problemSolution": {displayText: "Analyses problems & provides solutions", dataType:"selectOne", val:"", required: true, disable:false},
        "employTool": {displayText: "Employs tools of the job competently", dataType:"selectOne", val:"", required: true, disable:false},
        "orgTime": {displayText: "Organization & time management skills", dataType:"selectOne", val:"", required: true, disable:false},
        "achievement": {displayText: "Achivements/comments", dataType:"text", val:"", required: true, disable:false},
        "managerRating": {displayText: "Manager Rating", dataType:"selectOne", val:"", required: true, disable:false},
        "managerComment": {displayText: "Manager Comment", dataType:"text", val:"", required: true, disable:false},
        // "directorRating": {displayText: "Director Rating", dataType:"selectOne", val:"", required: true, disable:false},
        // "directorComment": {displayText: "Director Comment", dataType:"text", val:"", required: true, disable:false},
    })
    const [workQuality, setWorkQuality] = useState({
        "accuracyPrecision": {displayText: "Accuracy or precision", dataType:"selectOne", val:"", required: true, disable:false},
        "thoroughness": {displayText: "Thoroughness", dataType:"selectOne", val:"", required: true, disable:false},
        "reliability": {displayText: "Reliability", dataType:"selectOne", val:"", required: true, disable:false},
        "responsiveness": {displayText: "Responsiveness to requests for help or service", dataType:"selectOne", val:"", required: true, disable:false},
        "awarenessConscious": {displayText: "Safety awareness & consciousness at work place", dataType:"selectOne", val:"", required: true, disable:false},
        "achievement": {displayText: "Achivements/comments", dataType:"text", val:"", required: true, disable:false},
        "managerRating": {displayText: "Manager Rating", dataType:"selectOne", val:"", required: true, disable:false},
        "managerComment": {displayText: "Manager Comment", dataType:"text", val:"", required: true, disable:false},
        // "directorRating": {displayText: "Director Rating", dataType:"selectOne", val:"", required: true, disable:false},
        // "directorComment": {displayText: "Director Comment", dataType:"text", val:"", required: true, disable:false},
    })
    const [workApproach, setWorkApproach] = useState({
        "seekWays": {displayText: "Actively seeks ways to streamline processes", dataType:"selectOne", val:"", required: true, disable:false},
        "ideaApproach": {displayText: "Open to new ideas and approaches", dataType:"selectOne", val:"", required: true, disable:false},
        "takeInitiatives": {displayText: "Takes initiative", dataType:"selectOne", val:"", required: true, disable:false},
        "planningOrg": {displayText: "Planning & organization", dataType:"selectOne", val:"", required: true, disable:false},
        "flexibleAdaptable": {displayText: "Flexible & Adaptable", dataType:"selectOne", val:"", required: true, disable:false},
        "trainingDevelopment": {displayText: "Seeks addtional training & development", dataType:"selectOne", val:"", required: true, disable:false},
        "achievement": {displayText: "Achivements/comments", dataType:"text", val:"", required: true, disable:false},
        "managerRating": {displayText: "Manager Rating", dataType:"selectOne", val:"", required: true, disable:false},
        "managerComment": {displayText: "Manager Comment", dataType:"text", val:"", required: true, disable:false},
        // "directorRating": {displayText: "Director Rating", dataType:"selectOne", val:"", required: true, disable:false},
        // "directorComment": {displayText: "Director Comment", dataType:"text", val:"", required: true, disable:false},
    })
    const [otherComment, setOtherComment] = useState({
        "actionPlan": {displayText: "Actions plans for the next review period", dataType:"text", val:"", required: true, disable:false},
        "employeeComment": {displayText: "Employee Comments", dataType:"text", val:"", required: true, disable:false},
        "overallMarking": {displayText: "Overall Marking", dataType:"text", val:"", required: true, disable:false},
        "trainDevGoal": {displayText: "Training & development goals", dataType:"text", val:"", required: true, disable:false},
        "managerComment": {displayText: "Manager Comment", dataType:"text", val:"", required: true, disable:false},
        // "directorComment": {displayText: "Director Comment", dataType:"text", val:"", required: true, disable:false},
    })
    const staffAppraisalData = useFetchPost(
        domain + "/api/AppraisalModule/appraisal.php?action=searchStaffAppraisal",
        { appraisalId: routeParams.id },
        refetch
    );
    
    // function handleOptionsFromDb(empList, dept){
    //     setEmployeeList(empList)
    //     let catAry = []
    //     empList.map((dt)=>{
    //         if(!catAry.includes(dt[1]) && dt[1] !== "Driver"){
    //             catAry.push(dt[1])
    //         }
    //     })
    //     setEmployeeCategory(catAry)
    //     setDept(dept)
    // }

    // useEffect(() => {
    //     fetch(
    //     "http://localhost/ECMS/public/api/RecruitmentModule/manpowerCreateEmployeeInfo.php",
    //     {
    //         method: "GET",
    //     }
    //     )
    //     .then((res) => res.json())
    //     .then((json) => {
    //         handleOptionsFromDb(json[3], json[1])
    //     })
    //     .catch((err) => {
    //         console.log(err);
    //     });
        
    //   }, []);
    
    function handleChanges(varName, value, f){
        if(f){
            if(f === "interSkill"){
                let items = interSkill[varName]
                items.val = value
                setInterSkill({
                    ...interSkill,
                    [varName]: {...items}
                })
            }else if(f === "techSkill"){
                let items = techSkill[varName]
                items.val = value
                setTechSkill({
                    ...techSkill,
                    [varName]: {...items}
                })
            }else if(f === "workApproach"){
                let items = workApproach[varName]
                items.val = value
                setWorkApproach({
                    ...workApproach,
                    [varName]: {...items}
                })
            }else if(f === "workQuality"){
                let items = workQuality[varName]
                items.val = value
                setWorkQuality({
                    ...workQuality,
                    [varName]: {...items}
                })
            }else if(f === "otherComment"){
                let items = otherComment[varName]
                items.val = value
                setOtherComment({
                    ...otherComment,
                    [varName]: {...items}
                })
            }
        }else{
            let items = field[varName]
            items.val = value

            setField({
                ...field,
                [varName]: {...items}
            })
        }

    }

    function handleSubmit(e){
        e.preventDefault()
        let inputData = {}
        
        var passValidationCheck = true

        //validation for staff apppraisal input
        if(from === "staff"){
            if(field["currentPositionYear"].val === "" || field["currentPositionYear"].val === null || interSkill["achievement"].val === "" || interSkill["achievement"].val === null || techSkill["achievement"].val === "" || techSkill["achievement"].val === null || workApproach["achievement"].val === "" || workApproach["achievement"].val === null || workQuality["achievement"].val === "" || workQuality["achievement"].val === null || otherComment["actionPlan"].val === "" || otherComment["actionPlan"].val === null || otherComment["employeeComment"].val === "" || otherComment["employeeComment"].val === null){
                passValidationCheck = false
            }
        }else if(from === "manager" || from === "supervisor" || from === "director"){
            if(interSkill["withCoWorker"].val === "" || interSkill["withCoWorker"].val === null 
            || interSkill["withSupervisor"].val === "" || interSkill["withSupervisor"].val === null 
            || interSkill["withEndUsers"].val === "" || interSkill["withEndUsers"].val === null 
            || interSkill["teamParticipation"].val === "" || interSkill["teamParticipation"].val === null 
            || interSkill["teamcontributions"].val === "" || interSkill["teamcontributions"].val === null 
            || interSkill["commitmentToTeamSuccess"].val === "" || interSkill["commitmentToTeamSuccess"].val === null 
            || interSkill["managerRating"].val === "" || interSkill["managerRating"].val === null 
            || interSkill["managerComment"].val === "" || interSkill["managerComment"].val === null 
            || techSkill["jobKnowledge"].val === "" || techSkill["jobKnowledge"].val === null 
            || techSkill["problemSolution"].val === "" || techSkill["problemSolution"].val === null 
            || techSkill["employTool"].val === "" || techSkill["employTool"].val === null 
            || techSkill["orgTime"].val === "" || techSkill["orgTime"].val === null 
            || techSkill["managerRating"].val === "" || techSkill["managerRating"].val === null 
            || techSkill["managerComment"].val === "" || techSkill["managerComment"].val === null 
            || workQuality["accuracyPrecision"].val === "" || workQuality["accuracyPrecision"].val === null 
            || workQuality["thoroughness"].val === "" || workQuality["thoroughness"].val === null 
            || workQuality["reliability"].val === "" || workQuality["reliability"].val === null 
            || workQuality["responsiveness"].val === "" || workQuality["responsiveness"].val === null 
            || workQuality["awarenessConscious"].val === "" || workQuality["awarenessConscious"].val === null 
            || workQuality["managerRating"].val === "" || workQuality["managerRating"].val === null 
            || workQuality["managerComment"].val === "" || workQuality["managerComment"].val === null 
            || workApproach["seekWays"].val === "" || workApproach["seekWays"].val === null 
            || workApproach["ideaApproach"].val === "" || workApproach["ideaApproach"].val === null 
            || workApproach["takeInitiatives"].val === "" || workApproach["takeInitiatives"].val === null 
            || workApproach["planningOrg"].val === "" || workApproach["planningOrg"].val === null 
            || workApproach["flexibleAdaptable"].val === "" || workApproach["flexibleAdaptable"].val === null 
            || workApproach["trainingDevelopment"].val === "" || workApproach["trainingDevelopment"].val === null 
            || workApproach["managerRating"].val === "" || workApproach["managerRating"].val === null 
            || workApproach["managerComment"].val === "" || workApproach["managerComment"].val === null 
            || otherComment["overallMarking"].val === "" || otherComment["overallMarking"].val === null 
            || otherComment["trainDevGoal"].val === "" || otherComment["trainDevGoal"].val === null 
            || otherComment["managerComment"].val === "" || otherComment["managerComment"].val === null){
                passValidationCheck = false

                if(field["currentPositionYear"].val === "" || field["currentPositionYear"].val === null){
                    passValidationCheck = false
                }
            }

            if(empCode !== field["aReportManager"].val){
                Swal.fire(
                    "Permission Error!",
                    "Sorry, you are not allowed to modify this field",
                    // json[1],
                    // json[0] === 1 ? "success" : "error"
                );
                passValidationCheck = false
                return 0
            }
        }

        if(passValidationCheck){
            //Combine data for field
            Object.keys(field).map((k)=>{
                inputData[k] = field[k].val
            })
            
            //Combine data for interSkill
            var tmp = {}
            Object.keys(interSkill).map((k)=>{
                tmp[k] = interSkill[k].val
            })
            inputData["interSkill"] = JSON.stringify(tmp)

            //Combine data for techSkill
            var tmp = {}
            Object.keys(techSkill).map((k)=>{
                tmp[k] = techSkill[k].val
            })
            inputData["techSkill"] = JSON.stringify(tmp)

            //Combine data for workApproach
            var tmp = {}
            Object.keys(workApproach).map((k)=>{
                tmp[k] = workApproach[k].val
            })
            inputData["workApproach"] = JSON.stringify(tmp)

            //Combine data for workQuality
            var tmp = {}
            Object.keys(workQuality).map((k)=>{
                tmp[k] = workQuality[k].val
            })
            inputData["workQuality"] = JSON.stringify(tmp)

            //Combine data for otherComment
            var tmp = {}
            Object.keys(otherComment).map((k)=>{
                tmp[k] = otherComment[k].val
            })
            inputData["otherComment"] = JSON.stringify(tmp)
            
            var act = ""
            var nextRedirect = ""

            if(from === "staff"){
                act = "submitStaffAppraisal"
                nextRedirect = "sa"
            }else if(from === "manager"){
                act =  "submitManagerReview"
                nextRedirect = "ma"
            }else if(from === "director"){
                act = "submitDirectorApproval"
                nextRedirect = "da"
            }else if(from === "supervisor"){
                act = "submitSupervisorReview"
                nextRedirect = "supera"
            }

            fetch(
                domain + "/api/AppraisalModule/appraisal.php?action=" + act,
                {
                    method: "POST",
                    // mode: "same-origin",
                    //credentials: "same-origin",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(inputData),
                }
            )
            .then((res) => {
                console.log('res',res);
                return res.json()
            })
            .then((json) => {
                console.log('alert message', json);
                Swal.fire(
                    // "Success!",
                    // "Successfully updated",
                    json[0] === 1 ? 'Success!' : 'Error!',
                    json[1],
                    json[0] === 1 ? 'success' : 'error'
                );
            })
            .catch((err) => {
                console.log('error', err);
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong. Please try again later.',
                  });
            });
    
            // setCurAction("edit")
            
            history.push("/" + nextRedirect)

        }else{
            Swal.fire(
                "Empty field found!",
                "Please fill up all required field",
                // json[1],
                // json[0] === 1 ? "success" : "error"
            );
        }
    }

    // function handleCurAction(){
    //     setCurAction("submit")
    // }

    useEffect(()=>{
        if(staffAppraisalData.status === "fetched" && staffAppraisalData.data[0] !== 0){
            Object.keys(staffAppraisalData.data).map((k)=>{
                if(k === "techSkill" || k === "workQuality" || k === "interSkill" || k === "workApproach" || k ==="otherComment"){
                    //Convert JSON data back to object
                    var convertedData  = {}
                    if(staffAppraisalData.data[k]){
                        convertedData = parseCustomData(staffAppraisalData.data[k])
                        
                        //Apply database data to each of the different field
                        Object.keys(convertedData).map(((ik)=>{
                            handleChanges(ik, convertedData[ik], k)
                        }))
                    }

                }else{
                    if(staffAppraisalData.data[k]){
                        handleChanges(k, staffAppraisalData.data[k])
                    }            
                }
            })

            // if(staffAppraisalData.data[0]["interviewStatus"] !== "Pending"){
            //     setEditable(false)
            // }
            
            // fetch(
            // "http://localhost/ECMS/public/api/RecruitmentModule/interview.php?action=getIntFilled&id="+ routeParams.id,
            // {
            //     method: "GET",
            // }
            // )
            // .then(res => res.text())
            // .then((res) => {
            //     if(res){
            //         setFilled(JSON.parse(res)[0].filled)
            //     }else{
            //         console.log("failed to fetch")
            //     }
            // })
            // .catch((err) => {
            //     console.log(err);
            // });
        }
    },[staffAppraisalData.status])
    
    return(
        <div>
            {staffAppraisalData.status === "fetched"?
                <CreateStaffAppraisalForm 
                    module="Staff Appraisal"
                    field={field}
                    techSkill={techSkill}
                    interSkill={interSkill}
                    workQuality={workQuality}
                    workApproach={workApproach}
                    otherComment={otherComment}
                    evalOption={evalOption}
                    handleChanges={handleChanges}
                    ratingContainer={ratingContainer}
                    buttonStyle={buttonStyle}
                    handleMouseEnter={handleMouseEnter}
                    handleMouseLeave={handleMouseLeave}
                    isHover={isHover}
                    hoverField={hoverField}
                    buttonHoverStyle={buttonHoverStyle}
                    viewButtonStyle={viewButtonStyle}
                    viewButtonHoverStyle={viewButtonHoverStyle}
                    permission={permission}
                    handleSubmit={handleSubmit}
                    from={from}
                    empCode={empCode}
                />
            :
                <div>
                    Loading....
                </div>
            }
        </div>
    )
}